import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, [pathname]); // This effect runs every time the pathname changes

    return null; // This component doesn't render anything
};



// window.scrollTo({
//     top: 0,
//     left: 0,
//     behavior: 'smooth', // Optional: This adds a smooth scroll effect
// }); testsadasczxcxz

export default ScrollToTop;